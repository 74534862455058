import React from 'react'
import Layout from './layout/Layout'
import ScrollToTop from '../ScrollToTop'
import PreFooterSection from './views/PreFooterSection'
import PageHeaderSection from './ui/PageHeaderSection'
import Timeline from './views/Timeline'
import Helmet from 'react-helmet'

// images
import banner from '../assets/img/about/about-us-banner.jpg'
import img1 from '../assets/img/timeline/01@2x.png'
import img2 from '../assets/img/timeline/02@2x.png'
import img3 from '../assets/img/timeline/03@2x.png'
import img4 from '../assets/img/timeline/04@2x.png'
import img5 from '../assets/img/timeline/05@2x.png'
import img6 from '../assets/img/timeline/06@2x.png'
import img7 from '../assets/img/timeline/07@2x.png'
import img8 from '../assets/img/timeline/08@2x.png'

class AboutUs extends React.Component {

    render(){

        const timeline = [
            {
                year: "1936",
                heading: "Humble Beginnings",
                description: (
                    <>
                        <p>In 1936, Cecil Vinegar Works was born. The business had its humble beginnings under the name Khan’s Vinegar in a facility on Cecil Road, Salt River, which was the street that would later give the company its name.</p>
                    </>
                ),
                image: img1
            },
            {
                year: "1986",
                heading: "1986",
                description: (
                    <>
                        <p>
                        The Vesty group sold the business to Delmonte’ and the Epping factory was then relocated to the Firgrove production facility until 2009. 
                        </p>
                    </>
                ),
                image: img2
            },
            {
                year: "1993",
                heading: "Regulation Of Vinegar Industry",
                description: (
                    <>
                    <p>This year saw a management buyout and to regulate the vinegar industry, the South African Natural Vinegar Manufacture Association (SANVA) was created along with a seal used to denote “real” naturally fermented vinegar. At this stage, all fermentation at the facility was manually controlled. This meant that it was necessary for staff to come to the plant at all hours of the day and night to manually predict and discharge the machines.</p>
                    </>
                ),
                image: img3
            },
            {
                year: "1995",
                heading: "Automating For The Future",
                description: (
                    <p>After years of manual fermentation, the plant was finally automated in 1995. In the meantime, a joint venture was formed with the SA Dried Fruit Co-Op, now Pioneer Foods, giving company rights to the Safari brand.</p>
                ),
                image: img4
            },
            {
                year: "2000",
                heading: "Company Expansion",
                description: (
                    <p>In the year 2000, the company bought Illovo sugars’ vinegar business located in Alrode, Gauteng. A year later the company expanded by taking over NCP’s vinegar production in Germiston and subsequently relocated to the Alrode facility also known as Gauteng vinegar works.
                    </p>
                ),
                image: img5
            },
            {
                year: "2009",
                heading: "Product Acquisition",
                description: (
                    <p>In 2009, having grown further and needing bigger capacity the company moved the Firgrove production facility to a specially built factory in Strand, which is still home to the company today. In later years the acquisition of the Verjuice company and Verlaque condiments were added to the business
                    </p>
                ),
                image: img6
            },
            {
                year: "2015",
                heading: "New Ownership",
                description: (
                    <p>Finally, in 2015 Pioneer foods along with the company's private shareholders, sold the business to Libstar Operations (Pty) Ltd. 
                    </p>
                ),
                image: img7
            },
            {
                year: "2022",
                heading: "Modern Vision",
                description: (
                    <>
                        <p>Today, Cecil Vinegar Works is one of South Africa’s largest and most successful vinegar producers, supplying Safari as well as several other major supermarket private label brands. It has been producing for over 80 years and the vinegar works is still going strong due to the company constantly improving and upgrading its technology and delivering the vinegar that South Africa loves.</p>
                        <p>It has been a unique journey which it is not over yet and like our vinegars the company is just getting better with age.</p>
                    </>
                ),
                image: img8
            }
        ]

        return(
            <Layout styleType="transparent">
                <Helmet>
                    <title>About Us | Cecil Vinegar Works</title>
                    <meta name="description" content="Cecil Vinegar Works has been manufacturing naturally fermented vinegar since 1936. All our vinegars are produced from natural ingredients using the latest submerged fermentation technology. Our range covers a wide spectrum of naturally fermented vinegar products which ranges from retail packs of 200ml, 375ml, 500ml, 750ml and 2 liters, to bulk loads of 1,000L to 30,000L for industrial customers." />
                    <link rel="canonical" href={window.location} />
                </Helmet>
                <ScrollToTop />
                    <PageHeaderSection 
                        img={banner}
                    >
                        <h1>ABOUT US</h1>
                    </PageHeaderSection>
                    <section className='_section _section-about-us-intro'>
                        <div className='_grid'>
                            <div className='_column'>
                                <div className='_panel'>
                                    <h2 className='_section-heading'>Ancient History, Modern Vision</h2>
                                    <p>Cecil Vinegar Works has been manufacturing naturally fermented vinegar since 1936.</p> 
                                    <p>Since our vinegars are naturally fermented, they contain natural trace elements and nutrients derived from the raw material’s source. SA’s world-renowned Cape Winelands are where we source grapes for our wine, grape and balsamic vinegars. The finest locally grown apples are sourced from the Western Cape’s Elgin area for our apple cider vinegar.</p>
                                    <p>Our products are packed in various retail pack formats from 100ml to 2 Liters. We supply catering and home industry sectors in larger 5L and 25L formats, as well as industrial customers in bulk formats of 1 000L to 30 000L</p>
                                    <p>We offer contract packing services for Branded, Private Label and Dealer Own Brand products for both the domestic and export markets.</p>
                                    <p>Our products are available in all supermarkets across South Africa under the Safari and Retail brands and available in PET and glass packaging formats.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Timeline data={timeline} />
                <PreFooterSection />
            </Layout>
            
        )
    }
}

export default AboutUs