import React from 'react'
import Layout from './layout/Layout'
import ScrollToTop from '../ScrollToTop'
import PreFooterSection from './views/PreFooterSection'
import ContactForm from './forms/ContactForm'
import {connect} from 'react-redux'
import {
    sendContactMessage,
    resetForms
} from '../actions'
import history from '../history'
import Helmet from 'react-helmet'

//images
import libstar from '../assets/img/libstar-logo@2x.png'

class Contact extends React.Component {

    componentWillUnmount(){
        this.props.resetForms()
    }

    componentDidUpdate(){
        if(this.props.send.errors){
            setTimeout(() => {
                this.props.resetForms()
            }, 7000)
        }
    }

    onSubmit = formValues => {
        this.props.sendContactMessage(formValues)
    }

    render(){

        this.props.send.success && history.push('/contact/thankyou')

        return(
            <Layout>
                <Helmet>
                    <title>Contact Us | Cecil Vinegar Works</title>
                    <meta name="description" content="Strand Manufacturing Plant and Administrative Head Office: 36 Chilwan Crescent, Helderberg Industrial Park, Strand, Western Cape, 7140, South Africa, Tel: +27 (0)21 845 4001, Email: info@naturalvinegar.co.za; Gauteng Manufacturing Plant: 17 Jurie Street, Alrode, Gauteng, 1451, South Africa, Tel: +27 (0)11 864 4700" />
                    <link rel="canonical" href={window.location} />
                </Helmet>
                <ScrollToTop />
                <section className='_section _section-page-heading-normal'>
                    <div className='_grid'>
                        <div className='_column'>
                            <h1 className='_section-heading'>CONTACT US</h1>
                        </div>
                    </div>
                </section>
                <section className='_section _section-contact-us'>
                    <div className='_grid'>
                        <div className='_column'>
                            <div className='_panel' style={{marginBottom: '0'}}>
                                <div className='_panel-item'>
                                    <h3>Strand Manufacturing Plant and Administrative Head Office</h3>
                                    <div className='_hr' />
                                    <p>
                                        <a href="https://goo.gl/maps/U5MBY9UiLR8qn9Ut9" target="_blank" rel="noreferrer">
                                            36 Chilwan Crescent <br />
                                            Helderberg Industrial Park <br />
                                            Strand <br />
                                            Western Cape <br />
                                            7140 <br />
                                            South Africa <br />
                                        </a>
                                    </p>
                                    <p><strong>Tel:</strong> <a href="tel:+27218454001">+27 (0)21 845 4001</a> <br />
                                        <strong>Email:</strong> <a href="mailto:info@naturalvinegar.co.za">info@naturalvinegar.co.za</a></p>
                                </div>
                                <div className='_panel-item'>
                                    <h3>Gauteng Manufacturing Plant</h3>
                                    <div className='_hr' />
                                    <p>
                                        <a href="https://goo.gl/maps/vcYZS9TynXxSW1sN9" target="_blank" rel="noreferrer">
                                            17 Jurie Street <br />
                                            Alrode <br />
                                            Gauteng <br />
                                            1449 <br />
                                            South Africa <br />
                                        </a>
                                        
                                    </p>
                                    <p><strong>Tel:</strong> <a href="tel:+27118644700">+27 (0)11 864 4700</a></p>
                                </div>
                            </div>
                            <div className='_panel' style={{marginBottom: '0'}}>
                                <div className='_panel-item'>
                                    <h3>Contacts</h3>
                                    <div className='_hr' />
                                    <p>
                                        <strong className='_section-heading'>Customer Service \ Orders \ Export</strong>
                                    </p>
                                </div>
                            </div>
                            <div className='_panel' style={{marginBottom: '0'}}>
                                <div className='_panel-item'>
                                    <h4>Telephone</h4>
                                    <p>
                                        <a href="tel:+27214932460">+27 (0) 21 493 2460</a> <br />
                                        <a href="tel:+27214932470">+27(0)21 493 2470</a>
                                    </p>
                                </div>
                                <div className='_panel-item'>
                                    <h4>Email</h4>
                                    <p>
                                        <a href="mailto:orders@naturalvinegar.co.za">orders@naturalvinegar.co.za</a> <br />
                                        <a href="mailto:bilqees@naturalvinegar.co.za">bilqees@naturalvinegar.co.za</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='_section _section-contact-form'>
                    <div className='_grid'>
                        <div className='_column'>
                            <h2 className='_section-heading' style={{textAlign:'center'}}>Send us a message</h2>
                        </div>
                    </div>
                    <div className='_grid'>
                        <div className='_column'>
                            <div className='_panel'>
                                <ContactForm 
                                    onSubmit={this.onSubmit} 
                                    errors={this.props.send.errors ? this.props.send.errors : null}
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <section className='_section _section-contact-libstar'>
                    <div className='_grid'>
                        <div className='_column'>
                            <a href="https://www.libstar.co.za/" target="_blank" rel="noreferrer">
                                <img src={libstar} alt="Libstar" />
                            </a>
                            <p>Cecil Vinegar Works a division of Libstar Operations (Pty) Ltd</p>
                        </div>
                    </div>
                </section>
                <PreFooterSection />
            </Layout>
            
        )
    }
}

const mapStateToProps = state => {
    return {
        send: state.contactSatate
    }
}

export default connect(mapStateToProps, {
    sendContactMessage,
    resetForms
})(Contact)